import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import routes from "./Routes";
import Loading from "./components/Loading";
import "./utils/Language/Language";

function App() {
  return (
    <div className="app">
      <Suspense fallback={<Loading />}>
        <RouterProvider router={routes} />
      </Suspense>
    </div>
  );
}

export default App;
