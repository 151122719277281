import { lazy } from "react";
import { ACC_TKN } from "./utils/Constant";
import { Navigate, createBrowserRouter } from "react-router-dom";
import Cookies from "js-cookie";

//global path and name
export const homeRoute = { path: "/", name: "Home" };
export const loginRoute = { path: "/login", name: "Login" };
export const error_404 = { path: "/404", name: "Error 404" };
export const error_500 = { path: "/500", name: "Error 500" };

//private path and name
export const dashboardRoute = { path: "/dashboard", name: "menu.dashboard", icon: "dashboard" };
//warehouse
export const whsRoute = { path: "/warehouse", name: "menu.warehouse" };
export const stockRoute = { path: `${whsRoute.path}/viewStock`, name: "Stok Barang", icon: "warehouse" };
export const itemInRoute = { path: `${whsRoute.path}/viewItemIn`, name: "Barang Masuk" };
export const itemOutRoute = { path: `${whsRoute.path}/viewItemOut`, name: "Barang Keluar" };
export const returnSupplierRoute = { path: `${whsRoute.path}/viewReturSupplier`, name: "Pengembalian ke Pemasok" };
export const returnCustomerRoute = { path: `${whsRoute.path}/viewReturCustomer`, name: "Pengembalian dari Pelanggan" };
export const depreciationRoute = { path: `${whsRoute.path}/viewDepreciation`, name: "Penyusutan" };
// transaction
export const transRoute = { path: "/transaction", name: "menu.whstrans" };
export const transItemInRoute = { path: `${transRoute.path}/itemIn`, name: "common.itemin" };
export const transItemOutRoute = { path: `${transRoute.path}/itemOut`, name: "common.itemout" };
export const transReturnSupRoute = { path: `${transRoute.path}/returnSupplier`, name: "common.returnsupplier" };
export const transReturnCusRoute = { path: `${transRoute.path}/returnCustomer`, name: "common.returncustomer" };
export const convertionRoute = { path: `${transRoute.path}/convertion`, name: "common.convertion" };
export const stockOpnameRoute = { path: `${transRoute.path}/stockOpname`, name: "common.stockopname" };
export const itemMoveRoute = { path: `${transRoute.path}/itemMove`, name: "Perpindahan Barang" };
export const productionRoute = { path: `${transRoute.path}/production`, name: "Proses Barang" };
export const itemInListRoute = { path: `${transRoute.path}/itemInList`, name: "Daftar Barang Masuk" };
export const itemOutListRoute = { path: `${transRoute.path}/itemOutList`, name: "Daftar Barang Keluar" };
// master
export const masterRoute = { path: "/setting", name: "menu.master", icon: "settings" };
export const mUserRoute = { path: `${masterRoute.path}/user`, name: "common.user" };
export const mUnitRoute = { path: `${masterRoute.path}/unit`, name: "common.unit" };
export const mCategoryRoute = { path: `${masterRoute.path}/category`, name: "common.category" };
export const mCustomerRoute = { path: `${masterRoute.path}/customer`, name: "common.customer" };
export const mSupplierRoute = { path: `${masterRoute.path}/supplier`, name: "common.supplier" };
export const mGoodsRoute = { path: `${masterRoute.path}/goods`, name: "common.goods" };
export const mProfileRoute = { path: `${masterRoute.path}/profile`, name: "common.profile" };
export const mGroupingRoute = { path: `${masterRoute.path}/grouping`, name: "Pengelompokan Barang" };
export const mConvertionRoute = { path: `${masterRoute.path}/conversion`, name: "Konversi Barang" };
export const mTNCRoute = { path: `${masterRoute.path}/termAndCondition`, name: "Pengaturan TNC" };
export const mStorageRoomRoute = { path: `${masterRoute.path}/storageRoom`, name: "Ruang Penyimpanan" };
export const mBankAccRoute = { path: `${masterRoute.path}/bankAccount`, name: "Rekening" };
export const mPaymentMethodRoute = { path: `${masterRoute.path}/paymentMethod`, name: "Metode Pembayaran" };
export const mSPALRoute = { path: `${masterRoute.path}/hirePayment`, name: "Cara Pembayaran (SPAL)" };
export const mTimeUnloadandLoadSPALRoute = {
  path: `${masterRoute.path}/loadingPeriod`,
  name: "Waktu Bongkar & Muat (SPAL)",
};
export const mDemurrageSPALRoute = { path: `${masterRoute.path}/demurrage`, name: "Denda Keterlambatan (SPAL)" };
export const mProductionRoute = { path: `${masterRoute.path}/production`, name: "Master Proses Barang" };
// Sale
export const saleRoute = { path: "/btob/po", name: "menu.sales", icon: "point_of_sale" };
// E-commerce
export const ecommRoute = { path: "/ecommerce", name: "menu.ecommerce", icon: "shopping_cart" };
export const ecommRegisterRoute = { path: `${ecommRoute.path}/register`, name: "menu.ecommregister" };
export const ecommListRoute = { path: `${ecommRoute.path}/list`, name: "menu.ecommlist" };
// Purchase
export const purchaseRoute = { path: "/btob/poout", name: "menu.purchase", icon: "shopping_bag" };
// supply chain
export const supplyChainRoute = { path: "/supplychain", name: "menu.supplychain", icon: "local_shipping" };
export const scPointRoute = { path: "/supplychain_point", name: "menu.supplychain_point" };
export const scGoodsReqRoute = { path: "/supplychain_request", name: "menu.supplychain_request" };

//end private path and name

// component
const Login = lazy(() => import("./page/login/Login"));
const MainLayout = lazy(() => import("./layouts/MainLayout"));
const Error_404 = lazy(() => import("./page/error/Error404"));
const Error_500 = lazy(() => import("./page/error/Error500"));

const Dashboard = lazy(() => import("./page/dashboard/Dashboard"));
const Warehouse = lazy(() => import("./page/warehouse/Warehouse"));
const ItemStock = lazy(() => import("./page/warehouse/item_stock/ItemStock"));
const ItemInOut = lazy(() => import("./page/warehouse/item_in_out/ItemInOut"));
const ReturnSupplierCustomer = lazy(() => import("./page/warehouse/return_supplier_customer/ReturnSupplierCustomer"));
const ItemDepreciation = lazy(() => import("./page/warehouse/item_depreciation/ItemDepreciation"));

const Transaction = lazy(() => import("./page/transaction/Transaction"));
const TransItemInOut = lazy(() => import("./page/transaction/item_in_out/TransItemInOut"));

const Master = lazy(() => import("./page/master/Master"));
const Ecommerce = lazy(() => import("./page/ecommerce/Ecommerce"));
const Supplychain = lazy(() => import("./page/supplychain/Supplychain"));

// route group
const warehouseTabRoutes = [
  stockRoute,
  itemInRoute,
  itemOutRoute,
  returnSupplierRoute,
  returnCustomerRoute,
  depreciationRoute,
];

const transListRoutes = [
  transItemInRoute,
  transItemOutRoute,
  transReturnSupRoute,
  transReturnCusRoute,
  convertionRoute,
  stockOpnameRoute,
  itemMoveRoute,
  productionRoute,
  itemInListRoute,
  itemOutListRoute,
];

const masterListRoutes = [
  mUserRoute,
  mUnitRoute,
  mCategoryRoute,
  mCustomerRoute,
  mSupplierRoute,
  mGoodsRoute,
  mProfileRoute,
  mGroupingRoute,
  mConvertionRoute,
  mTNCRoute,
  mStorageRoomRoute,
  mBankAccRoute,
  mPaymentMethodRoute,
  mSPALRoute,
  mTimeUnloadandLoadSPALRoute,
  mDemurrageSPALRoute,
  mProductionRoute,
];

const ecommmerceListRoutes = [ecommRegisterRoute, ecommListRoute];
const supplychainListRoutes = [scPointRoute, scGoodsReqRoute];

const routeList = [
  // untuk breadcrum
  dashboardRoute,
  whsRoute,
  saleRoute,
  transRoute,
  masterRoute,
  ecommRoute,
  purchaseRoute,
  supplyChainRoute,
  ...warehouseTabRoutes,
  ...transListRoutes,
  ...ecommmerceListRoutes,
  ...masterListRoutes,
  ...supplychainListRoutes,
];

const warehouseRoutes = {
  element: <Warehouse />,
  children: [
    { ...whsRoute, element: <Navigate to={dashboardRoute.path} replace /> },
    { ...stockRoute, element: <ItemStock /> },
    { ...itemInRoute, element: <ItemInOut type="in" /> },
    { ...itemOutRoute, element: <ItemInOut type="out" /> },
    { ...returnSupplierRoute, element: <ReturnSupplierCustomer type="supplier" /> },
    { ...returnCustomerRoute, element: <ReturnSupplierCustomer type="customer" /> },
    { ...depreciationRoute, element: <ItemDepreciation /> },
  ],
};

const transactionRoutes = {
  element: <Transaction />,
  children: [
    { ...transRoute, element: <Navigate to={dashboardRoute.path} replace /> },
    { ...transItemInRoute, element: <TransItemInOut type="in" /> },
    { ...transItemOutRoute, element: <TransItemInOut type="out" /> },
    { ...transReturnSupRoute, element: <div>return sup</div> },
    { ...transReturnCusRoute, element: <div>return cus</div> },
    { ...convertionRoute, element: <div>Konversi</div> },
    { ...stockOpnameRoute, element: <div>stockOpname</div> },
    { ...itemMoveRoute, element: <div>move item</div> },
    { ...productionRoute, element: <div>production</div> },
    { ...itemInListRoute, element: <div>item in list</div> },
    { ...itemOutListRoute, element: <div>item out list</div> },
  ],
};

const masterRoutes = {
  element: <Master />,
  children: [
    { ...masterRoute, element: <Navigate to={dashboardRoute.path} replace /> },
    { ...mUserRoute, element: <>user</> },
    { ...mUnitRoute, element: <>unit</> },
    { ...mUnitRoute, element: <></> },
    { ...mGoodsRoute, element: <></> },
    { ...mCategoryRoute, element: <></> },
    { ...mCustomerRoute, element: <></> },
    { ...mSupplierRoute, element: <></> },
    { ...mProfileRoute, element: <></> },
    { ...mGroupingRoute, element: <></> },
    { ...mConvertionRoute, element: <></> },
    { ...mTNCRoute, element: <></> },
    { ...mStorageRoomRoute, element: <></> },
    { ...mBankAccRoute, element: <></> },
    { ...mPaymentMethodRoute, element: <></> },
    { ...mSPALRoute, element: <></> },
    { ...mTimeUnloadandLoadSPALRoute, element: <></> },
    { ...mDemurrageSPALRoute, element: <></> },
    { ...mProductionRoute, element: <></> },
  ],
};

const ecommerceRoutes = {
  element: <Ecommerce />,
  children: [
    { ...ecommRoute, element: <Navigate to={dashboardRoute.path} replace /> },
    { ...ecommRegisterRoute, element: <></> },
    { ...ecommListRoute, element: <></> },
  ],
};

const supplyChainRoutes = {
  element: <Supplychain />,
  children: [
    { ...supplyChainRoute, element: <Navigate to={dashboardRoute.path} replace /> },
    { ...scPointRoute, element: <></> },
    { ...scGoodsReqRoute, element: <></> },
  ],
};

//end route group

// main
const routes = createBrowserRouter(
  [
    { ...loginRoute, element: <Login /> },
    { ...error_404, element: <Error_404 /> },
    { ...error_500, element: <Error_500 /> },
    {
      element: requireAuth(<MainLayout />),
      children: [
        { path: "*", element: <Navigate to={error_404.path} replace /> },
        { ...homeRoute, element: <Navigate to={dashboardRoute.path} replace /> },
        { ...dashboardRoute, element: <Dashboard /> },
        { ...saleRoute, element: <>Penjualan</> },
        { ...purchaseRoute, element: <>Pembelian</> },
        warehouseRoutes,
        transactionRoutes,
        ecommerceRoutes,
        supplyChainRoutes,
        masterRoutes,
      ],
    },
  ],
  { future: { v7_normalizeFormMethod: true } }
);

export default routes;
export {
  routeList,
  warehouseTabRoutes,
  transListRoutes,
  ecommmerceListRoutes,
  supplychainListRoutes,
  masterListRoutes,
};
// end main

// function list
function requireAuth(component) {
  // for route that require authentication to access
  const token = Cookies.get(ACC_TKN);
  const path = window.location.pathname;
  if ([undefined, null, ""].includes(token)) return <Navigate to="/login" state={{ prev: path }} />;
  return component;
}
// end function list
