
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from './en.json'
import id from './id.json'
import { storage } from "../Storage";

const initLanguage = storage.getItem('LNG') ?? "id";
i18next.use(initReactI18next).init({
    resources: { en, id }, lng: initLanguage, interpolation: { escapeValue: false }
})

export default i18next;