import React from "react";
import { Box, CircularProgress } from "@mui/material";

function Loading(props) {
  return (
    <Box sx={{ position:'absolute', left: "50%", top: "50%" }}>
      <CircularProgress />
    </Box>
  );
}

export default Loading;
